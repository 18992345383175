import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-search-change',
  templateUrl: './search-change.component.html',
  styleUrls: ['./search-change.component.scss']
})
export class SearchChangeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
